import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';

const GET_QUERY = gql`
  query ($id: ID!, $limits: Int, $cursor: Int, $query: String) {
    node(id: $id) {
      ... on CompanyShop {
        variations(limits: $limits, cursor: $cursor, query: $query) {
          nextCursor
          totalCount
          nodes {
            id
            sku
            active
            product {
              name
            }
          }
        }
      }
    }
  }
`;

export default async function (source, { skus = [] } = {}) {
  return await infinityFetch({
    query: GET_QUERY,
    variables: {
      id: source?.id,
      query: `sku:(${skus.map((sku) => `"${sku}"`).join(',')})`,
    },
    getConnection: (data) => data?.node?.variations,
  });
}
