import React, { useState } from 'react';
import { StateParamsConnectionPageMarker } from '../../../components/ConnectionPageMaker';
import { GET_LINKED_APPOINTMENTS } from '../query';
import { APPOINTMENT_ATTEND } from './ActionComplete';
import { APPOINTMENT_CANCEL } from './ActionCancel';
import { AppointmentStatus } from '../../../shared/omniwe-types';
import { Grid } from '@material-ui/core';
import ActionComplete from './ActionComplete';
import ActionCancel from './ActionCancel';
import MenuSystemButton from '../../../components/MenuSystemButton';
import { client } from '../../../shared/apollo';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import SystemButton from '../../../components/SystemButton';
import { parseConnection } from '../../../shared';
import { Icon } from '../../../components/IconRender';
import { theme } from '../../../theme';
import EllipsisTypography from '../../../components/EllipsisTypography';
import { translate } from '../../../shared/translate';

class LinkedAppointmentCard extends StateParamsConnectionPageMarker {
  state = {
    ...this.state,
    gql: {
      get: GET_LINKED_APPOINTMENTS,
    },
    selectionMode: true,
    refreshable: false,
    onRowClick: (e, row) => {
      const { onRowClick } = this.props;
      if (onRowClick) onRowClick(row);
    },
    fields: [
      {
        title: translate.booking_reference || '預約編號',
        value: (row) => {
          const selected = this.props?.values?.id === row?.id;
          return <span style={{ fontWeight: selected ? 'bold' : undefined }}>{row?.referenceNo}</span>;
        },
      },
      {
        title: translate.booking_item || '預約項目',
        width: 300,
        value: (row) => {
          const selected = this.props?.values?.id === row?.id;
          return (
            <>
              <EllipsisTypography>
                <span style={{ fontWeight: selected ? 'bold' : undefined }}>
                  【{row?.serviceBundle?.name}】{row?.service?.name}
                </span>
              </EllipsisTypography>
              <EllipsisTypography variant={'caption'}>
                <span style={{ fontWeight: selected ? 'bold' : undefined }}>{row?.orderItem?.remark}</span>
              </EllipsisTypography>
            </>
          );
        },
      },
      {
        width: 150,
        title: translate.status || '狀態',
        render: (row) => {
          return <RenderFn row={row} />;
          function RenderFn({ row }) {
            const [loading, setLoading] = useState(false);
            switch (row?.status) {
              case 'CONFIRMED':
                return (
                  <MenuSystemButton
                    disabled={loading}
                    loading={loading}
                    color={'default'}
                    items={[
                      {
                        label: (
                          <>
                            <Icon
                              icon={'faCircle'}
                              type={'pro-solid'}
                              style={{ marginRight: theme.spacing(1), color: theme.palette.success.main }}
                            />{' '}
                            {translate.attended || '已出席'}
                          </>
                        ),
                        onClick: async () => {
                          try {
                            setLoading(true);
                            await client.mutate({
                              mutation: APPOINTMENT_ATTEND,
                              variables: { id: row?.id },
                            });
                          } catch (e) {
                            toast.error(errorParser(e));
                          } finally {
                            setLoading(false);
                          }
                        },
                      },
                      {
                        label: (
                          <>
                            <Icon
                              icon={'faCircle'}
                              type={'pro-solid'}
                              style={{ marginRight: theme.spacing(1), color: theme.palette.error.main }}
                            />{' '}
                            {translate.cancel || '取消'}
                          </>
                        ),
                        onClick: async () => {
                          try {
                            setLoading(true);
                            await client.mutate({
                              mutation: APPOINTMENT_CANCEL,
                              variables: { id: row?.id },
                            });
                          } catch (e) {
                            toast.error(errorParser(e));
                          } finally {
                            setLoading(false);
                          }
                        },
                      },
                    ]}
                  >
                    {AppointmentStatus[row.status].label}
                  </MenuSystemButton>
                );
              case 'COMPLETED':
                return <SystemButton color={'success'}>{AppointmentStatus[row.status].label}</SystemButton>;
              case 'CANCELLED':
                return <SystemButton color={'error'}>{AppointmentStatus[row.status].label}</SystemButton>;
              default:
                return <></>;
            }
          }
        },
      },
    ],
    limits: 999,
    // menus: [MenuComplete, MenuCancel],
  };

  getData = (data) => {
    const { nodes } = parseConnection(data?.linkedAppointments);
    return parseConnection(
      nodes.filter((node) => {
        return !!node?.startedAt;
      }),
    );
  };

  getExtraFetchVariables = () => {
    const __linkedAppointmentCardOrderId =
      sessionStorage.getItem('__linkedAppointmentCardOrderId') || this.props.values?.order?.id;
    sessionStorage.setItem('__linkedAppointmentCardOrderId', __linkedAppointmentCardOrderId);

    return {
      id: __linkedAppointmentCardOrderId,
    };
  };

  renderExtras({ nodes, totalCount, nextCursor }) {
    const appointmentIds = this.getSelectionFilter()?.id?.value ?? [];
    const appointments = appointmentIds.map((id) => nodes.find((node) => node.id === id)).filter(Boolean);

    return (
      <div>
        <Grid container spacing={1}>
          <Grid item>
            <ActionComplete appointments={appointments.filter((ap) => ['CONFIRMED', 'PENDING'].includes(ap?.status))} />
          </Grid>
          <Grid item>
            <ActionCancel appointments={appointments.filter((ap) => ['CONFIRMED', 'PENDING'].includes(ap?.status))} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default LinkedAppointmentCard;
